<template>
  <improve-your-results-template
    :patient-photo="patientPhoto"
    @back="onBack"
    @continue="onContinue"
    @skip="onSkip"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';

import ImproveYourResultsTemplate from '@/modules/questionnaire/components/steps/photo-analysis/photo-analysis-results/improve-your-results/ImproveYourResultsTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';

import { types as questTypes } from '@/modules/questionnaire/store/types';
import { SKIPPED_SECTIONS } from '@/modules/questionnaire/constants/sections';

export default {
  name: 'ImproveYourResults',
  components: { ImproveYourResultsTemplate },
  mixins: [makeStep([])],
  computed: {
    ...mapState({
      patientPhoto: state => state.questionnaire.patientPhoto
    })
  },
  mounted() {
    if (!this.patientPhoto) {
      this.fetchPatientQuestionnairePhoto();
    }
  },
  methods: {
    ...mapActions({
      addSkippedSection: questTypes.actions.ADD_SKIPPED_SECTION,
      removeSkippedSection: questTypes.actions.REMOVE_SKIPPED_SECTION,
      fetchPatientQuestionnairePhoto: questTypes.actions.FETCH_PATIENT_QUESTIONNAIRE_PHOTO
    }),
    onBack() {
      this.showPreviousStep();
    },
    onContinue() {
      this.removeSkippedSection(SKIPPED_SECTIONS.DYSFUNCTIONS_SECTIONS);
      this.showNextStep();
    },
    onSkip() {
      this.addSkippedSection(SKIPPED_SECTIONS.DYSFUNCTIONS_SECTIONS);
      this.showNextStep();
    }
  }
};
</script>
