<template>
  <div class="lets-fine-tune">
    <tagged-photo
      class="lets-fine-tune__photo q-mt8"
      :photo="patientPhoto"
      tag="preliminaryResults.aiTag"
    />

    <h2
      class="lets-fine-tune__title lets-fine-tune__content u-typography-helvetica u-text-display u-text-display--s"
    >
      {{ $t('prestartScreen1.title') }}
    </h2>
    <p class="lets-fine-tune__text lets-fine-tune__content u-typography-helvetica u-text u-text--m">
      {{ $t('prestartScreen1.introText') }}
    </p>

    <span
      class="lets-fine-tune__time fine-tune-time u-typography-helvetica u-text u-text--link u-text--xs"
      ><u-icon class="fine-tune-time__icon" name="clock" />
      {{ $t('prestartScreen1.label.min', ['3 - 4']) }}</span
    >

    <footer class="lets-fine-tune__footer lets-fine-tune__content lets-fine-tune-footer q-mb8">
      <div class="fine-tune-action-buttons lets-fine-tune-footer__action-buttons">
        <u-button
          class="fine-tune-action-buttons__button"
          kind="secondary"
          @click="$emit('back')"
          >{{ $t('prestartScreen1.action.backToResults') }}</u-button
        >
        <u-button class="fine-tune-action-buttons__button" @click="$emit('continue')">{{
          $t('action.continue')
        }}</u-button>
      </div>

      <span class="lets-fine-tune-footer__skip u-typography-helvetica u-text u-text--xs"
        >{{ $t('prestartScreen1.label.allCorrect') }}
        <a href="#" @click.prevent="$emit('skip')">{{
          $t('prestartScreen1.action.skipThisStep')
        }}</a></span
      >
    </footer>
  </div>
</template>

<script>
import { UButton, UIcon } from 'universkin-shared';

import TaggedPhoto from '@/modules/questionnaire/components/common/TaggedPhoto';

export default {
  name: 'ImproveYourResultsTemplate',
  components: { TaggedPhoto, UButton, UIcon },
  props: {
    patientPhoto: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.lets-fine-tune {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  box-sizing: border-box;

  &__title {
    margin: 24px 0 16px;
  }

  &__text {
    margin: 0;
    color: var(--u-color-grey-600);
  }

  &__time {
    height: 30px;
    width: fit-content;
    margin: 16px 0 24px 0;
  }

  &__footer {
    width: 100%;
  }
}

.fine-tune-action-buttons {
  display: flex;
  flex-direction: column-reverse;

  &__button {
    &:last-child {
      margin-bottom: 8px;
    }
  }
}

.fine-tune-time {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--u-color-grey-300);
  border-radius: 8px;
  padding: 5px 8px;
  box-sizing: border-box;

  &__icon {
    margin-right: 8px;
  }
}

.lets-fine-tune-footer {
  &__action-buttons {
    margin-bottom: 16px;
  }

  &__skip {
    color: var(--u-color-grey-600);

    & a,
    & a:active {
      color: #000000;
      font-size: inherit;
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

@media (min-width: $tablet-start) {
  .lets-fine-tune {
    padding: 0 40px;

    &__photo {
      margin-top: 16px;
    }

    &__footer {
      margin-bottom: 16px;
    }
  }

  .fine-tune-action-buttons {
    flex-direction: row;

    &__button {
      flex: 1 1 0;

      &:first-child {
        margin-right: 16px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
