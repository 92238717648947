<template>
  <div class="tagged-photo">
    <div class="tagged-photo__photo-container tagged-photo-container">
      <div class="tagged-photo-container__corner"></div>
      <div class="tagged-photo-container__corner"></div>
      <div class="tagged-photo-container__corner"></div>
      <div class="tagged-photo-container__corner"></div>

      <img class="tagged-photo-container__photo" :src="photo" />
    </div>

    <div v-if="tag" class="tagged-photo__tag q-mt16">
      <img
        class="q-mr8"
        src="@/modules/questionnaire/assets/images/icons/check-filled.svg"
        alt="check icon"
      />
      <span class="u-typography-helvetica u-text u-text--xs u-text-display--bold">
        {{ $t(tag) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TaggedPhoto',
  props: {
    photo: {
      type: String,
      default: ''
    },
    tag: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.tagged-photo {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__photo-container {
    position: relative;
    width: 150px;
    height: 157px;
  }

  &__tag {
    padding: 5px 8px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 6px;
    color: var(--u-color-white);
    background-color: var(--u-color-blue-300);
  }
}

.tagged-photo-container {
  &__photo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 130px;
    height: 137px;
    object-fit: cover;
  }

  &__corner {
    position: absolute;
    border-width: 2px;
    border-color: var(--u-color-blue-300);
    width: 20px;
    height: 20px;

    &:nth-child(1) {
      top: 0;
      left: 0;

      border-top-style: solid;
      border-left-style: solid;
    }

    &:nth-child(2) {
      top: 0;
      right: 0;

      border-top-style: solid;
      border-right-style: solid;
    }

    &:nth-child(3) {
      bottom: 0;
      right: 0;

      border-bottom-style: solid;
      border-right-style: solid;
    }

    &:nth-child(4) {
      bottom: 0;
      left: 0;

      border-bottom-style: solid;
      border-left-style: solid;
    }
  }
}
</style>
